import Support from '@/models/Support'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  actions: {
    async getSupport({ commit }, params) {
      const query = Support.page(params.page || 1)

      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query.params({ limit: 20 }).get()

      commit('setReportsList', res.data)
      commit('setSupportListMeta', res.meta)
    },
    async addSupport({ commit }, payload) {
      return Api.post('support-contents', payload)
    },
    async updateSupport({ commit }, payload) {
      return Api.put(`support-contents/${payload.id}`, payload.form)
    },
    async deleteSupport({ commit }, payload) {
      return Api.delete(`support-contents/${payload}`)
    },
  },

  mutations: {
    setReportsList(state, supports) {
      each(supports, (report) => {
        const exist = find(state.list, { id: report.id })
        if (!exist) {
          state.list.push(new Support(report))
        }
      })
    },

    clearSupportList(state) {
      state.list = []
    },

    setSupportListMeta(state, meta) {
      state.listMeta = meta
    },
  },
}
