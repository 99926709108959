import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth'
import settings from './settings'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    auth,
    {
      path: '/',
      name: 'home',
      redirect: { name: 'users' },
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
      children: [
        {
          path: 'inbox',
          name: 'inbox',
          component: () =>
            import(/* webpackChunkName: "inbox" */ '@/views/Home/Inbox'),
        },
        {
          path: 'users',
          name: 'users',
          component: () =>
            import(/* webpackChunkName: "users" */ '@/views/Home/Users'),
        },
        {
          path: 'plans',
          name: 'plans',
          component: () =>
            import(/* webpackChunkName: "plans" */ '@/views/Home/Plans'),
        },
        {
          path: 'storage',
          name: 'storage',
          component: () =>
            import(/* webpackChunkName: "storage" */ '@/views/Home/Storage'),
        },
        {
          path: 'users/:id',
          name: 'user.details',
          component: () =>
            import(
              /* webpackChunkName: "users" */ '@/views/Home/Users/UserDetails'
            ),
        },
        {
          path: 'content',
          name: 'content',
          component: () =>
            import(/* webpackChunkName: "content" */ '@/views/Home/Content'),
        },
        {
          path: 'notification',
          name: 'notification',
          component: () =>
            import(
              /* webpackChunkName: "push-notification" */ '@/views/Home/Notification'
            ),
        },
        settings,
        {
          path: 'reports',
          name: 'reports',
          component: () =>
            import(/* webpackChunkName: "reports" */ '@/views/Home/Reports'),
        },
      ],
    },
  ],
})
