import Plan from '@/models/Plan'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  mutations: {
    setPlanList(state, plans) {
      each(plans, (plan) => {
        const exist = find(state.list, { id: plan.id })
        if (!exist) {
          state.list.push(new Plan(plan))
        }
      })
    },

    clearPlanList(state) {
      state.list = []
    },

    setPlanListMeta(state, meta) {
      state.listMeta = meta
    },
  },

  actions: {
    async getPlanList({ commit }, params) {
      const query = Plan.page(params.page || 1)
        .include('prices')
        .orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query.params({ limit: 20 }).get()

      commit('setPlanList', res.data)
      commit('setPlanListMeta', res.meta)
    },

    async updatePlan({ commit }, params) {
      return Api.put(`/plans/${params.id}`, params.form)
    },

    async updatePrice({ commit }, params) {
      return Api.put(
        `/plans/${params.planId}/prices/${params.priceId}`,
        params.form
      )
    },

    async getExtraStorage({ commit }, params) {
      return Api.get('settings/app/extra_storage_price')
    },

    async updateExtraStorage({ commit }, { price }) {
      return Api.post('settings/app/extra_storage_price', {
        content: price,
      })
    },
  },
}
